<template>
  <div
    class="fixed inset-x-0 bottom-0 z-40 text-sm md:pb-4 md:text-md"
    v-if="open"
  >
    <div class="flex justify-center">
      <div
        class="flex flex-col items-center w-full p-4 space-y-2 shadow-strong bg-[#323232] bg-opacity-80 md:p-2 md:rounded-2xl md:w-auto md:flex-row md:space-y-0 md:space-x-4 md:inline-flex"
      >
        <p class="flex-1 md:ml-2 text-white text-base">
          {{ dataLocale.data.title }}
          <nuxt-link class="underline" to="/page/cookies-policy">{{
            $t('footer.cookie_policy')
          }}</nuxt-link>
        </p>
        <div class="flex space-x-2">
          <button
            @click="closePopup()"
            class="flex items-center justify-center px-6 py-1.5 text-md font-semibold focus:outline-none rounded-lg text-white bg-success-bold shadow-light"
          >
            OK</button
          ><button
            @click="dontTrack()"
            class="flex items-center focus:outline-none justify-center px-3 py-1.5 text-md text-white font-semibold rounded-lg bg-grow"
          >
            {{ dataLocale.data.button }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import DataLocale from "~~/config/interface/DataLocale";

const open = ref(false);
const closePopup = () => {
  open.value = false;
  SaveLocalStorage("1");
};
const dontTrack = () => {
  open.value = false;
  SaveLocalStorage("2");
};
const SaveLocalStorage = (value: string) => {
  if (typeof localStorage !== "undefined") {
    localStorage.setItem("__cookie_consent", value);
  }
};
onMounted(() => {
  {
    nextTick(() => {
      if (typeof localStorage !== "undefined") {
        if (!localStorage.getItem("__cookie_consent")) {
          open.value = true;
        }
      }
    });
  }
});
const dataLocale = new DataLocale([
  {
    locale: "en",
    title: " We use cookies to improve our service.",
    link: "Learn more",
    button: "Don't Track Me",
  },
  {
    locale: "vi",
    title: "Chúng có tôi sử dụng cookie để cải thiện dịch vụ.",
    link: "Tìm hiểu thêm",
    button: "Không theo dõi tôi",
  },
  {
    locale: "fr",
    title: "Nous utilisons des cookies pour améliorer notre service.",
    link: "En savoir plus",
    button: "Ne me suivez pas",
  },
  {
    locale: "it",
    title: "Utilizziamo i cookie per migliorare il nostro servizio.",
    link: "Scopri di più",
    button: "Non tracciarmi",
  },
  {
    locale: "es",
    title: "Utilizamos cookies para mejorar nuestro servicio.",
    link: "Aprende más",
    button: "No me rastrees",
  },
  {
    locale: "nl",
    title: "We gebruiken cookies om onze service te verbeteren.",
    link: "Meer informatie",
    button: "Volg me niet",
  },
  {
    locale: "pt",
    title: "Utilizamos cookies para melhorar o nosso serviço.",
    link: "Saiba mais",
    button: "Não me rastreie",
  },
  {
    locale: "ja",
    title: "サービス向上のためクッキーを使用しています。",
    link: "詳しくはこちら",
    button: "追跡しないでください",
  },
  {
    locale: "zh",
    title: "我们使用 cookie 来改善我们的服务。",
    link: "了解更多",
    button: "不要追踪我",
  },
  {
    locale: "de",
    title: "Wir verwenden Cookies, um unseren Service zu verbessern.",
    link: "Erfahren Sie mehr",
    button: "Nicht verfolgen",
  },
]);
</script>
