<template>
  <VitePwaManifest />
  <NuxtLoadingIndicator />
  <div>
    <LayoutsNavigationApp :native="native" />
    <NuxtPage />
    <LayoutsFooterApp />
    <ClientOnly>
      <notifications position="bottom right" />
    </ClientOnly>
  </div>
  <LayoutsCookieConsent />
</template>

<script>
import DataLocale from "~~/config/interface/DataLocale";
import { useQrCreateStore } from "~~/stores/qrcreate";

export default defineComponent({
  setup() {
    var bodyClass = "font-matter";
    const dataLocale = new DataLocale([
      {
        locale: "en",
        title: "QR Gateway - QR Code Generator",
        description:
          "QR Gateway provides QR code generation, QR code design customization, management, and statistics services. It can generate QR codes directly from images, documents, audio and video for company promotion, product introduction, education and training, travel itinerary, marketing promotion.",
      },
    ]);
    useHead({
      title: dataLocale.data.title,
      meta: [
        { name: "description", content: dataLocale.data.description },
        { name: "theme-color", content: "#00a13b" },
        {
          name: "msapplication-TileColor",
          content: "#00a13b",
        },
        {
          name: "msapplication-TileImage",
          content: "/ms-icon-144x144.png",
        },
      ],
      link: [
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/icon-180x180.png",
        },
        {
          rel: "apple-touch-icon-precomposed",
          sizes: "180x180",
          href: "/icon-180x180.png",
        },
        {
          rel: "icon",
          type: "image/x-icon",
          href: "/favicon.ico",
        },
        {
          rel: "apple-touch-icon",
          sizes: "57x57",
          href: "/apple-icon-57x57.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "60x60",
          href: "/apple-icon-60x60.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "72x72",
          href: "/apple-icon-72x72.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "76x76",
          href: "/apple-icon-76x76.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "114x114",
          href: "/apple-icon-114x114.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "120x120",
          href: "/apple-icon-120x120.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "144x144",
          href: "/apple-icon-144x144.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "152x152",
          href: "/apple-icon-152x152.png",
        },
        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-icon-180x180.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "192x192",
          href: "/android-icon-192x192.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicon-32x32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "96x96",
          href: "/favicon-96x96.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicon-16x16.png",
        },
      ],
      bodyAttrs: {
        class: bodyClass,
      },
      script: [
        { src: "/js/qrcode.min.js", async: true },
        { src: "/js/canvas2svg.js", async: true },
        { src: "/js/fontkit.min.js", async: true },
      ],
    });
    const native = ref(true);
    const qrcreate = useQrCreateStore();
    const datajd = useDataJd();
    const runtimeConfig = useRuntimeConfig();
    useSchemaOrg([
      defineOrganization({
        name: "QR Gateway",
        sameAs: [
          "https://www.facebook.com/qrgateway",
          "https://twitter.com/qrgateway",
          "https://www.youtube.com/c/qrgateway",
          "https://www.behance.net/qrgateway",
          "https://www.linkedin.com/company/qrgateway",
          "https://www.crunchbase.com/organization/qr-gateway",
          "https://www.producthunt.com/products/qr-gateway"
        ],
        image: datajd.assets("/images/logo.png"),
        url: datajd.currentHost(),
      }),
      defineWebSite({
        name: dataLocale.data.title,
        url: datajd.currentHost(),
        inLanguage: datajd.inLanguages(),
        description: dataLocale.data.description,
      }),
    ]);

    return {
      native,
      qrcreate,
      runtimeConfig,
    };
  },
  created() {
    this.initNativeLayout();
  },
  mounted() {
    this.$nextTick(() => {
  //     const scriptID = "nuxt-microsoft-clarity";

  //     if (document.getElementById(scriptID)) {
  //       return;
  //     }

  //     const script = document.createElement("script");
  //     script.type = "text/javascript";
  //     script.id = scriptID;
  //     script.innerHTML = `
  //   (function(c,l,a,r,i,t,y){
  //     c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
  //     t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
  //     y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  //   })(window, document, "clarity", "script", "${this.runtimeConfig.public.ms_clarity}");
  // `;
  //     document.head.appendChild(script);

  
    });
  },
  methods: {
    initNativeLayout() {
      if (
        this.$route.params.type ||
        this.$route.name?.includes("index___") ||
        this.$route.name?.includes("qr-code-scanner__")
      ) {
        this.native = false;
      } else {
        this.native = true;
      }
      this.qrcreate.imageData = "";
    },
  },
  watch: {
    $route(v) {
      this.initNativeLayout();
    },
  },
});
</script>
